<template>
  <b-modal
    id="modal-tasks-skip"
    no-stacking
    @show="initializeModal"
  >
    <!--begin::header-->
    <template
      #modal-header
    >
      <h5 class="modal-title">
        {{ trans('tasks.modals.skip.title') }}
      </h5>
    </template>
    <!--begin::header-->

    <!--begin::body-->
    <template
      #default
    >
      <!--begin::content-->
      <p class="" v-html="trans('tasks.modals.skip.desc', { taskDesc: taskDesc })">
      </p>
      <!--end::content-->
    </template>
    <!--end::body-->

    <!--begin::footer-->
    <template
      #modal-footer="{ cancel }"
    >
      <b-btn
        variant="secondary"
        class="px-7 py-4"
        @click="cancel"
      >
        {{ capitalize(transChoice('global.cancel', 0)) }}
      </b-btn>
      <b-btn
        variant="warning"
        class="px-7 py-4"
        :class="submitButtonAnimation ? 'spinner spinner-light spinner-right pr-12' : ''"
        :disabled="submitButtonAnimation"
        @click="skipTask()"
      >
        <span v-if="submitButtonAnimation">
          {{ capitalize(trans('tasks.modals.skip.btn.loading')) }}
        </span>
        <span v-else>
          {{ capitalize(trans('tasks.modals.skip.btn.default')) }}
        </span>
      </b-btn>
    </template>
    <!--begin::footer-->
  </b-modal>
</template>

<script>
  import { ref, computed } from '@vue/composition-api'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'
  import * as objectPath from 'object-path'
  import { TASKS } from '@src/store/tasks'

  // context exploded:
  // attrs
  // slots
  // refs
  // emit
  // parent
  // root

  export default {
    name: 'ModalTasksSkip',
    props: {
      taskData: {
        type: Object,
        required: true
      }
    },
    setup (props, { root, emit }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const submitButtonAnimation = ref(false)

      const taskId = computed(() => objectPath.get(props, 'taskData.id', false))
      const taskDesc = computed(() => objectPath.get(props, 'taskData.desc'), '')

      const skipTask = async () => {
        console.log('Skipping task....')
        submitButtonAnimation.value = true

        const skipFunc = fb.functions.httpsCallable('backendTasksSkipTask')
        await skipFunc({
          id: taskId.value
        }).then((res) => {
          console.log('SkipResult: ', res)
          submitButtonAnimation.value = false

          const errors = objectPath.get(res, 'data.errors', false)

          if (errors !== false) {
            root.$bvToast.toast(trans('tasks.modals.skip.toast.error.msg', { error: errors[Object.keys(errors)[0]] }), { // eslint-disable-line max-len
              title: trans('tasks.modals.skip.toast.error.title'),
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000
            })
          } else {
            root.$bvToast.toast(trans('tasks.modals.skip.toast.success.msg'), {
              title: trans('tasks.modals.skip.toast.success.title'),
              variant: 'success',
              solid: true,
              autoHideDelay: 5000
            })
            // Update the "oppgaver"-count
            root.$store.dispatch(TASKS.ACTIONS.FETCH_TASKS, { status: 0, limit: false }) // eslint-disable-line object-property-newline, max-len
            emit('updateTaskItem', {
              result: true,
              id: taskId.value
            })
            root.$bvModal.hide('modal-tasks-skip')
          }
        }).catch((err) => {
          submitButtonAnimation.value = false
          root.$bvToast.toast(trans('tasks.modals.skip.toast.error.msg', { error: err }), {
            title: trans('tasks.modals.skip.toast.error.title'),
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000
          })
          console.log('ERRORS:', err)
        })
      }

      const initializeModal = () => {
        console.log('TaskDATA from Modal: ', props.taskData)
        submitButtonAnimation.value = false
      }

      return {
        trans,
        transChoice,
        capitalize,
        initializeModal,
        taskDesc,
        submitButtonAnimation,
        skipTask
      }
    }
  }
</script>

<style lang="scss">

</style>
