<template>
  <div>
    <!--begin::Tasks card-->
    <b-card
      class="card-custom gutter-b"
      no-body
    >
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <b-card-title>
          <h2 class="card-label">
            {{ trans('tasks.index.title') }}
            <span class="d-block text-muted pt-2 font-size-sm">
              {{ trans('tasks.index.desc') }}
            </span>
          </h2>
        </b-card-title>
        <div class="card-toolbar">
          <b-btn
            :variant="taskProps.status === 0 ? 'primary' : 'light'"
            class="py-3 px-4 mr-2"
            size="sm"
            :disabled="taskProps.status === 0"
            @click="setStatus(0)"
          >
            {{ trans('tasks.index.toolbar.pending') }}
          </b-btn>
          <b-btn
            :variant="taskProps.status === false ? 'primary' : 'light'"
            class="py-3 px-4"
            size="sm"
            :disabled="taskProps.status === false"
            @click="setStatus(false)"
          >
            {{ trans('tasks.index.toolbar.all') }}
          </b-btn>
        </div>
      </div>
      <div class="card-body" style="min-height: 80px;">
        <!--begin::tasklist-->
        <div class="list-taskstyle">
          <div
            class="d-flex list-item"
            v-for="(item, key) in items"
            :key="'item-' + key"
          >
            <!--begin::date-and-time-->
            <div class="list-label">
              <p>
                {{ momentFullDate(item.createdOn) }}
              </p>
              <p>
                {{ transChoice('global.time', 1) }} {{ momentHoursMinutesSeconds(item.createdOn) }}
              </p>
            </div>
            <!--end::date-and-time-->

            <!--begin::content-->
            <div class="d-flex flex-column list-content">
              <div
                class="list-content-inline"
              >
                <div
                  class="
                  d-flex
                  rounded
                  p-4
                "
                  :class="item.bgClass"
                >
                  <!--begin--icon-->
                  <div
                    class="symbol symbol-40 mr-2"
                    :class="`symbol-${ item.iconBgClass }`"
                  >
                    <div class="symbol-label">
                      <i :class="`${ item.icon } text-${ item.iconClass }`"></i>
                    </div>
                  </div>
                  <!--end:icon-->
                  <!--begin::details-->
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <!--begin::title and description-->
                    <div class="px-2">
                      <p class="text-dark font-weight-bold mb-1">
                        {{ item.title }}
                      </p>
                      <!--end::title-->

                      <!--begin::description-->
                      <p class="font-size-sm text-dark-50 mb-0" v-html="item.desc">
                        {{ item.desc }}
                      </p>
                      <!--end::description-->

                      <!--begin::completed-by-->
                      <p
                        v-if="item.completedBy !== ''"
                        class="d-flex align-items-center font-size-sm mt-2 mb-0 font-weight-bold"
                      >
                        <i class="fad fa-user mr-2" style="font-size: 12px;"></i>
                        <span>
                          {{ item.completedBy }}
                        </span>
                      </p>
                      <!--end::completed-by-->
                    </div>
                    <!--end::title and description-->

                    <!--begin::status and action-->
                    <div class="d-flex px-2 align-items-center flex-shrink-0">
                      <!--begin::status-->
                      <div>
                        <span
                          class="label label-rounded label-inline mr-4 label-lg"
                          :class="item.pill.class"
                        >
                          {{ item.pill.label }}
                        </span>
                      </div>
                      <!--end::status-->

                      <!--begin::action-btn-->
                      <div v-if="item.btn" class="pr-2">
                        <b-btn
                          :to="item.btn.to"
                          variant="success"
                          size="sm"
                          class="pl-4"
                        >
                          {{ item.btn.label }} <i class="far fa-chevron-right ml-2"></i>
                        </b-btn>

                        <b-btn
                          :ref="`task-skip-${ item.id }`"
                          variant="warning"
                          size="sm"
                          class="ml-2"
                          @click="openModal(item, 'skip')"
                        >
                          {{ trans('tasks.index.btn.skipTask') }} <i class="fad fa-angle-double-right ml-2"></i>
                        </b-btn>
                      </div>
                      <!--end::action-btn-->
                    </div>
                    <!--end::status and action-->
                  </div>
                  <!--end::details-->
                </div>
              </div>
            </div>
            <!--end::content-->
          </div>
        </div>
        <!--end::tasklist-->
        <!--begin::more-btn-->
        <div class="d-flex justify-content-center align-items-center flex-wrap mt-8">
          <div class="d-flex align-items-center py-3">
            <div class="d-flex align-items-center">
              <Loader
                v-if="taskProps.loading"
                :text="trans('tasks.index.btn.loading')"
                loader-class="relative"
                loader-style="margin-right: 80px;"
              ></Loader>
              <b-btn
                v-else-if="taskProps.showMoreBtn"
                ref="logs_showmorebtn"
                variant="primary"
                class="py-4 px-6"
                size="sm"
                @click="loadMore(taskProps.lastVisibleDoc)"
              >
                {{ trans('tasks.index.btn.loadMore') }}
              </b-btn>
            </div>
          </div>
        </div>
        <!--end::more-btn-->

        <!--begin::Modals-->
        <ModalTasksSkip
          @updateTaskItem="updateTasks"
          :task-data="taskData"
        ></ModalTasksSkip>
        <!--end::Modals-->
      </div>
    </b-card>
    <!--end::Tasks card-->
  </div>
</template>


<script>
  import * as objectPath from 'object-path'
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  import { trans, transChoice } from '@src/core/helpers/translate'
  import { computed, onBeforeMount, onMounted, ref } from '@vue/composition-api'

  import useDateTime from '@src/use/datetime'
  import ModalTasksSkip from '@src/components/modals/tasks/Skip'

  import useTasks from '@src/use/tasks'

  // import { capitalize } from '@src/core/helpers/textUtils'

  export default {
    name: 'ProductsIndex',
    components: {
      Loader: () => import('@src/components/content/Loader'),
      ModalTasksSkip
    },
    setup (props, { root, refs }) {
      const taskData = ref({})

      onBeforeMount(() => {
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
      })

      onMounted(() => {
        root.$store.dispatch(SET_BREADCRUMB, [
          {
            title: trans('tasks.index.title'),
            route: '/tasks'
          }
        ])
      })

      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const { momentFullDate, momentHoursMinutesSeconds } = useDateTime()

      const taskProps = ref({
        limit: 10,
        loading: true,
        orderBy: 'createdOn',
        sortOrder: 'desc',
        lastVisibleDoc: true,
        showMoreBtn: true,
        status: 0 // 0 = active/pending tasks
      })

      const tasks = ref([])

      const { faIcons, itemStyles, getTaskDetails } = useTasks()

      const loadMore = async (paginateDoc) => {
        const btn = refs.logs_showmorebtn
        if (btn !== undefined) btn.classList.add('spinner', 'spinner-light', 'spinner-right', 'pr-14')

        const getTasks = fb.functions.httpsCallable('backendTasksGetTasks')

        return await getTasks({
          data: {
            limit: taskProps.value.limit,
            orderBy: taskProps.value.orderBy, // disabled - TODO: Fix dates in backend
            sort: taskProps.value.sortOrder,
            status: taskProps.value.status,
            paginateDoc
          }
        }).then((res) => {
          console.log('RESULTS: ', res)
          if (taskProps.value.loading) tasks.value = []
          taskProps.value.showMoreBtn = Object.keys(objectPath.get(res, 'data.result', {})).length >= taskProps.value.limit // eslint-disable-line max-len

          Object.keys(objectPath.get(res, 'data.result', {})).forEach((doc) => {
            tasks.value.push(res.data.result[doc])
          })

          if (btn !== undefined) btn.classList.remove('spinner', 'spinner-light', 'spinner-right', 'pr-14')
          taskProps.value.lastVisibleDoc = objectPath.get(res, 'data.paginateDoc.lastVisibleDoc', true)
        })
      }

      // Old "created" Vue trigger:
      loadMore(taskProps.value.lastVisibleDoc).then(() => {
        taskProps.value.loading = false
      })

      const items = computed(() => tasks.value.map((entry) => {
        const { desc, btn } = getTaskDetails(entry)

        return {
          id: objectPath.get(entry, 'id', null),
          createdOn: objectPath.get(entry, 'createdOn', {}),
          icon: faIcons[objectPath.get(entry, 'type', 'missing')],
          title: trans(`tasks.index.list.${objectPath.get(entry, 'type', 'missing')}.title`),
          desc,
          btn,
          bgClass: itemStyles[objectPath.get(entry, 'status', 4)].bgClass,
          iconBgClass: itemStyles[objectPath.get(entry, 'status', 4)].iconBgClass,
          iconClass: itemStyles[objectPath.get(entry, 'status', 4)].iconClass,
          pill: itemStyles[objectPath.get(entry, 'status', 4)].pill,
          variant: objectPath.get(entry, 'variant', 'info'),
          completedBy: objectPath.get(entry, 'data.completedByUser.name', '') !== ''
            ? trans(`tasks.index.list.${ objectPath.get(entry, 'type', 'missing') }.completedBy`, {
              username: objectPath.get(entry, 'data.completedByUser.name', trans('tasks.index.list.missing.by'))
            })
            : ''
        }
      }))

      const setStatus = (status) => {
        taskProps.value.loading = true
        taskProps.value.status = status

        loadMore(true).then(() => {
          taskProps.value.loading = false
        })
      }

      const openModal = (tData, modal) => {
        taskData.value = tData
        console.log('TASK-DATA: ', taskData.value, modal)
        // templateData.value = templates.value[tId]
        // await new Promise((resolve) => setTimeout(resolve, 200)) // Populate the styletypeData
        root.$bvModal.show(`modal-tasks-${ modal }`)
      }

      const updateTasks = (data) => {
        const result = objectPath.get(data, 'result', false)
        const tId = objectPath.get(data, 'id', false)
        if (result && tId) {
          console.log('tasks ref (array): ', tasks.value)
          const taskKey = tasks.value.findIndex((item) => item.id === tId)
          console.log('TaskKey: ', taskKey)
          tasks.value[taskKey].status = 2
        }
        console.log('Updating tasks:', data)
      }

      return {
        trans,
        transChoice,
        momentFullDate,
        momentHoursMinutesSeconds,
        // Log list specifics
        items,
        taskProps,
        loadMore,
        setStatus,
        openModal,
        taskData,
        updateTasks
      }
    }
  }
</script>

<style lang="scss">
  table {
    &#synclog {
      td {
        vertical-align: middle;
      }
    }
  }

  .list-item {
    overflow: hidden;

    &.loading {
      height: 1.8rem;
    }
  }
</style>
